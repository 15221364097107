 .error-validation{
    font-size: 14px;
    color: red;
    margin-top: 10px;
}

.college-label {
    font-weight: 600;
    
}
.main{
    background-color: #fff;
}

.card {
    background: #70e1f5;
        background: -webkit-linear-gradient(to right, #ffd194, #70e1f5);
        background: linear-gradient(to right, #ffd194, #70e1f5);
}
.sidebar-nav .nav-link, .sidebar-nav .nav-link:hover,.sidebar-nav .nav-link.collapsed{
    background: none;
}
.sidebar {
    background: #70e1f5;
    background: -webkit-linear-gradient(to bottom, #ffd194, #70e1f5);
    background: linear-gradient(to bottom, #ffd194, #70e1f5);
}
.rdt_TableHeader, .rdt_Table .rdt_TableHeadRow , .rdt_TableRow, .rdt_Pagination {
    background: #70e1f5;
        background: -webkit-linear-gradient(to right, #ffd194, #70e1f5);
        background: linear-gradient(to right, #ffd194, #70e1f5);
    color: #000;
}
.rdt_Table .rdt_TableHeadRow{
    color: #000;
    font-size: 14px;
    font-weight: 500;
}
.card-body h5 {
    margin: 18px 0;
    padding-bottom: 10px;
    display: inline-block;
    color: #1e439b;
    font-size: 22px;
    border-bottom: 3px solid #ff5501;
    font-weight: 600;
    margin-bottom: 30px;
}

.card-body header{
    background: -webkit-linear-gradient(to right, #ffd194, #70e1f5);
    background: linear-gradient(to right, #ffd194, #70e1f5);
    color: #000;
}

form label{
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

button {
    padding: 8px 12px;
    /* margin: 8px 0 0; */
    font-family: 'Montserrat', sans-serif;
    border: 2px solid #78788c;
    background: 0;
    color: #5a5a6e;
    cursor: pointer;
    transition: all .3s;
}

button:hover {
    background: #78788c;
    color: #fff;
}

/* --- Animated Buttons --- */
.move-btn {
    background-color: transparent;
    display: inline-block;
    width: 100px;
    background-image: none;
    padding: 8px 10px;
    margin-bottom: 20px;
    border-radius: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.5, 1.65, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.5, 1.65, 0.37, 0.66);
}
.move-btn-disabled {
    background-color: transparent;
    display: inline-block;
    width: 100px;
    background-image: none;
    padding: 8px 10px;
    margin-bottom: 20px;
    border-radius: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.5, 1.65, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.5, 1.65, 0.37, 0.66);
    cursor: not-allowed;
}

.move-btn-re {
    border: 2px solid #ff5501;
    box-shadow: inset 0 0 0 0 #ff5501;
    color: #ff5501;
}

.move-btn-su {
    border: 2px solid #1e439b;
    box-shadow: inset 0 0 0 0 #1e439b;
    color: #1e439b;
}

.move-btn-re:focus,
.move-btn-re:hover,
.move-btn-re:active {
    background-color: transparent;
    color: #FFF;
    border-color: #ff5501;
    box-shadow: inset 96px 0 0 0 #ff5501;
}

.move-btn-su:focus,
.move-btn-su:hover,
.move-btn-su:active {
    background-color: transparent;
    color: #FFF;
    border-color: #1e439b;
    box-shadow: inset 96px 0 0 0 #1e439b;
}
@media (min-width: 720px) {
  .logout-btn {
    display: none;
  }
}
/* --- Media Queries --- */

.move-btn-su-disabled{
    border: 2px solid #4b4b4b;
    box-shadow: inset 0 0 0 0 #4b4b4b;
    color: #4b4b4b;
}
.move-btn-su-disabled:focus,
.move-btn-su-disabled:hover,
.move-btn-su-disabled:active {
    background-color: transparent;
    color: #FFF;
    border-color: #4b4b4b;
    box-shadow: inset 96px 0 0 0 #4b4b4b;
}
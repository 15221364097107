  * {
    padding: 0;
    margin: 0;
}
.loader-main-div {
    width: 100%;
    display: block;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 9999;
}

.loader-second-div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-spinner {
    z-index: 99999;
    width: 60px;
    height: 60px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
